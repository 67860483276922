import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";
import Spinner from "../../components/Spinner";
import CompanyInfoDisplay from "./CompanyInfoDisplay";
import { useQuery } from "@tanstack/react-query";
import apiGet from "../../libs/helpers";
import { Activity, CompanyAdminUser, LeaderScore, Role, Survey, User } from "../../types";
import { EnvelopeIcon, PhoneIcon, ChevronRightIcon, PlusIcon, HomeIcon, PencilIcon } from "@heroicons/react/24/outline";
import { useSearchParams, Link } from "react-router-dom";
import AdminModal from "../../components/AdminModal";
import AdminTable from "../../components/AdminTable";
import Button from "../../components/Button";
import JobMapping from "../../components/JobMapping";
import PointOfContactCard from "../../components/PointOfContactCard";
import SubscriptionInfo from "../../components/SubscriptionInfo";
import SurveyList from "../../components/SurveyList";
import PointOfContactEditable from "./PointOfContactEditable";
import useApi from "../../hooks/useApi";
import ActivityTable from "../../components/ActivityTable";
import CompanyList from "./CompanyList";
import UsersTableWithPagination from "../../components/assessments/UsersTableWithPagination";

export default function CompanyDetails() {
  const location = useLocation();

  const auth = useContext(AuthContext);
  const { getApiData } = useApi();
  const navigate = useNavigate();

  let companyId = location.pathname.replace("/company/", "");

  if (!Number.isInteger(parseInt(companyId))) {
    navigate("/companies");
  }

  const { data: company } = useQuery<Company>({
    queryKey: ["company", companyId],
    queryFn: () => getApiData(`/strata/companies/${companyId}`),
    enabled: Number.isInteger(parseInt(companyId)),
  });

  const { data: parentCompanies } = useQuery<Company[]>({
    queryKey: ["parentCompanies", companyId],
    queryFn: () => getApiData(`/strata/companies/parents/${companyId}`),
    initialData: [],
    enabled: Number.isInteger(parseInt(companyId)),
  });

  const { data: pointOfContacts } = useQuery<PointOfContact[]>({
    queryKey: ["pointOfContacts", companyId],
    queryFn: () => getApiData(`/strata/point-of-contacts/company/${companyId}`),
    initialData: [],
    enabled: Number.isInteger(parseInt(companyId)),
  });

  const { data: users } = useQuery<User[]>({
    queryKey: ["users", companyId],
    queryFn: async () => getApiData(`/strata/companies/users/${companyId}`),
    initialData: [],
    enabled: !!companyId,
  });

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const { data: surveys } = useQuery<Survey[]>({
    queryKey: ["surveys", companyId],
    queryFn: () => getApiData(`/strata/surveys/company/${companyId}`),
    initialData: [],
    enabled: Number.isInteger(parseInt(companyId)),
  });

  const { data: activities } = useQuery<Activity[]>({
    queryKey: ["activity", company],
    queryFn: () => getApiData(`/strata/activity/company/${companyId}`),
    initialData: [],
    enabled: Number.isInteger(parseInt(companyId)),
  });

  const [searchParams] = useSearchParams();

  const [pocEditId, setPocEditId] = useState<number>();
  const [showAddPoc, setShowAddPoc] = useState<boolean>(false);

  const currentTabId = searchParams.get("tab") ?? "info";

  const tabs = [
    { id: "info", name: "Info" },
    {
      id: "pointOfContact",
      name: "Point of Contact",
    },
    {
      id: "surveys",
      name: "Assessments",
    },
    {
      id: "participants",
      name: "Participants",
    },
    {
      id: "jobMapping",
      name: "Job Mapping",
    },
    { id: "admins", name: "Admins" },
    {
      id: "subscription",
      name: "Subscription Info",
    },
    {
      id: "sub-companies",
      name: "Sub-Companies",
    },
    {
      id: "activity",
      name: "Activity",
    },
  ];

  const pages: { name: string; href: string }[] = [];
  pages.push({
    name: "Companies",
    href: `/companies`,
  });

  for (let i = parentCompanies.length - 1; i >= 0; i--) {
    pages.push({
      name: parentCompanies[i].company_name,
      href: `/company/${parentCompanies[i].company_id}`,
    });
  }
  pages.push({
    name: company ? company.company_name : "",
    href: `/company/${company?.company_id}`,
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies", auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/companies`, auth.sessionInfo?.idToken),
    initialData: [],
  });

  function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
  }

  const subCompanies = company ? companies.filter((c) => c.parent_company_id === company.company_id) : [];

  if (company) {
    return (
      <div className="mx-auto m-1">
        {/* Breadcrumbs */}
        <nav aria-label="Breadcrumb" className="flex -mt-2">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to="/" className="text-gray-400 hover:text-gray-500">
                  <HomeIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0" />
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {pages.map((page) => (
              <li key={page.name}>
                <div className="flex items-center">
                  <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                  <Link to={page.href} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
        <div className="mx-auto">
          <div className="sm:flex sm:items-end sm:space-x-5">
            <div className="sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
              <div className="2xl:block mt-2 min-w-0 flex-1">
                <h1 className="text-2xl font-bold text-gray-900 truncate">{company.company_name}</h1>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{company.internal_name}</dd>
              </div>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="mt-6 lg:mt-1 overflow-scroll">
          <div className="border-b border-gray-200">
            <div className="max-w-5xl">
              <nav className="-mb-px flex space-x-5" aria-label="Tabs">
                {tabs.map((tab) => (
                  <div
                    key={tab.id}
                    onClick={() => {
                      navigate(`/company/${company.company_id}?tab=${tab.id}`);
                      //setCurrentTabId(tab.id);
                    }}
                    className={classNames(
                      currentTabId === tab.id ? "border-indigo-500 text-gray-900" : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                    )}
                  >
                    {tab.name}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Info */}
        {currentTabId === "info" && (
          <div className="mt-3 max-w-5xl">
            <CompanyInfoDisplay company={company} />
          </div>
        )}

        {/* Point of Contact */}
        {currentTabId === "pointOfContact" && (
          <div className="mt-2 pb-12">
            {pocEditId || showAddPoc ? (
              <div className="my-5">
                <PointOfContactEditable
                  pointOfContact={pointOfContacts.find((poc: any) => poc.poc_id === pocEditId)}
                  isUpdate={pocEditId ? true : false}
                  companyId={company.company_id}
                />
                <Button
                  text="Cancel"
                  variant="secondary"
                  onClick={() => {
                    setPocEditId(undefined);
                    setShowAddPoc(false);
                  }}
                />
              </div>
            ) : (
              <div>
                {pointOfContacts.length > 0 ? (
                  <div>
                    <div className="mt-6 flex justify-end">
                      <Button text="Add Point of Contact" onClick={() => setShowAddPoc(true)} />
                    </div>
                    <div className="mt-5 grid grid-cols-1 gap-4 sm:grid-cols-2 mb-4">
                      {pointOfContacts.map((poc: any, index: number) => (
                        <div key={index}>
                          <PointOfContactCard pointOfContact={poc} setPocEditId={(id: number) => setPocEditId(id)} />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-8">
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No point of contacts</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by creating a new project.</p>
                    <div className="mt-6 flex justify-center">
                      <Button text="Add Point of Contact" onClick={() => setShowAddPoc(true)} />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {/* Participants */}
        {currentTabId === "participants" && (
          <div className="mx-auto bg-white mt-5">
            <UsersTableWithPagination users={users} limit={25} />
          </div>
        )}

        {/* Surveys */}
        {currentTabId === "surveys" && surveys && (
          <div className="mx-auto bg-white mt-5">
            <SurveyList surveys={surveys} hideCompanySelect={true} />
          </div>
        )}

        {/* Job Mapping */}
        {currentTabId === "jobMapping" && <JobMapping companyId={company.company_id} />}

        {/* Admins */}
        {currentTabId === "admins" && (
          <div className="mt-4">
            <AdminTable admins={companyAdminUsers.filter((cau) => cau.companyIds.find((id) => id === company.company_id))} companyId={company.company_id} />
          </div>
        )}

        {/* Subscription Info */}
        {currentTabId === "subscription" && (
          <div className="mb-4 mt-4">
            <SubscriptionInfo company={company} />
          </div>
        )}

        {/* Sub Companies */}
        {currentTabId === "sub-companies" && (
          <div className="mt-2 bg-white">
            {subCompanies.length > 0 ? (
              <CompanyList companies={subCompanies} />
            ) : (
              <div className="text-center">
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No Sub-Companies</h3>
              </div>
            )}
          </div>
        )}

        {/* Activty */}
        {currentTabId === "activity" && (
          <div className="bg-white">
            <ActivityTable activities={activities} />
          </div>
        )}
      </div>
    );
  } else {
    return <Spinner />;
  }
}

import { CompanyAdminUser } from "../types";

export function formatPhoneNumber(phoneNumberString: string) {
  // Remove all non-digit characters
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");

  // Remove leading '1' if present (for US country code)
  if (cleaned.length === 11 && cleaned[0] === "1") {
    cleaned = cleaned.slice(1);
  }

  // Match the cleaned string to a 10-digit phone number pattern
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }

  // Return the original input if the formatting does not apply
  return phoneNumberString;
}

export function validateEmail(email: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
}

export function validatePhoneNumber(phone: string) {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone)
}

export default async function apiGet(endpoint: string, auth?: string | undefined) {
  const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
    headers: {
      "Content-Type": "application/json",
      Authorization: auth ?? "",
    },
  });
  const body = await response.json();

  if (body.name === "TokenExpiredError") {
    window.location.reload();
  }

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return body;
}

export function getInitialsFromName(name: string): string {
  return name.split(" ").length > 1 ? name.trim().split(" ")[0][0] + name.trim().split(" ").slice(-1)[0][0] : name.trim().split(" ")[0][0];
}

export function getUserAttribute(user: CompanyAdminUser, attribute: string) {
  return user.Attributes?.find((att) => att.Name === attribute)?.Value;
}
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Button from "../../components/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import { Switch } from "@headlessui/react";
import Toggle from "../../components/Toggle";
import { Industry, StrataJobTitle } from "../../types";
import useApi from "../../hooks/useApi";

export default function EditCompany(props: { company: Company; setEditable: (value: boolean) => void }) {
  const { company, setEditable } = props;
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const { getApiData } = useApi();

  const { data: companyTypes } = useQuery<any[]>({
    queryKey: ["companyTypes", auth.sessionInfo?.idToken],
    queryFn: () => getApiData(`/strata/companies/get/types`),
    initialData: [],
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies", auth.sessionInfo?.idToken],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  const { data: industries } = useQuery<Industry[]>({
    queryKey: ["industries"],
    queryFn: () => getApiData(`/strata/companies/get/industries`),
    initialData: [],
  });

  const { data: strataJobTitles } = useQuery<StrataJobTitle[]>({
    queryKey: ["strataJobTitles"],
    queryFn: () => getApiData(`/strata/jobTitles`),
    initialData: [],
  });

  console.log(strataJobTitles);

  return (
    <div className="">
      <Formik
        initialValues={{
          company_name: company.company_name,
          internal_name: company.internal_name,
          company_type_id: company.company_type_id,
          parent_company_id: company.parent_company_id,
          has_assessments: company.has_assessments,
          is_billing: company.is_billing,
          company_address_1: company.company_address_1,
          company_address_2: company.company_address_2,
          company_city: company.company_city,
          company_state: company.company_state,
          company_zip: company.company_zip,
          industry_id: company.industry_id,
          all: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.company_name) {
            errors.company_name = "Company name is required";
          } else if (values.company_name.length > 255) {
            errors.company_name = "Company name must be 255 characters or less";
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true);

          const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/companies/${company.company_id}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.sessionInfo?.idToken ? auth.sessionInfo.idToken : "",
            },
            body: JSON.stringify(values),
          });

          if (response.status === 200) {
            setSubmitting(false);
            window.location.reload();
          } else {
            const body = await response.json();
            setErrors({ all: body.message });
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form>
            <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
              <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Company name
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2 w-full">
                    <Field
                      type="text"
                      name="company_name"
                      id="company_name"
                      autoComplete="company_name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_name" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label htmlFor="internal_name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Internal name
                    </label>
                    <p className="text-sm text-gray-500">Internal use only, not shown to customers</p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2 w-full">
                    <Field
                      type="text"
                      name="internal_name"
                      id="internal_name"
                      autoComplete="internal_name"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-sm sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="internal_name" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_type_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Company type
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      as="select"
                      name="company_type_id"
                      id="company_type_id"
                      autoComplete="type"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      {companyTypes.map((type: any, index: number) => (
                        <option key={index} value={type.company_type_id}>
                          {type.company_type_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage className="text-red-500 text-xs" name="company_type_id" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label htmlFor="parent_company_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Parent Company
                    </label>
                    <p className="text-sm text-gray-500">NOTE: Create the parent first and then select the parent company when creating its children</p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      as="select"
                      name="parent_company_id"
                      id="parent_company_id"
                      onChange={(e: any) => setFieldValue("parent_company_id", parseInt(e.target.value))}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      <option value={""}>None</option>
                      {companies.map((company, index) => (
                        <option key={index} value={company.company_id}>
                          {company.company_name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage className="text-red-500 text-xs" name="parent_company_id" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label htmlFor="parent_company_id" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Industry
                    </label>
                    <p className="text-sm text-gray-500">Determines cut-off scores and Strata job titles</p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-1">
                    <Field
                      as="select"
                      name="industry_id"
                      id="industry_id"
                      onChange={(e: any) => setFieldValue("industry_id", parseInt(e.target.value))}
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    >
                      {industries
                        .sort((a, b) => (a.industry_name > b.industry_name ? 1 : -1))
                        .map((industry, index) => (
                          <option key={index} value={industry.industry_id}>
                            {industry.industry_name}
                          </option>
                        ))}
                    </Field>
                    <ErrorMessage className="text-red-500 text-xs" name="industry_id" component="div" />
                  </div>
                  <div>
                    <div className="text-sm text-gray-700">
                      <span className="text-gray-900 font-semibold">Confidence Interval:</span> {industries.find((i) => i.industry_id === values.industry_id)?.lower_bound} -{" "}
                      {industries.find((i) => i.industry_id === values.industry_id)?.upper_bound}
                    </div>
                    <div className="text-sm text-gray-700">
                    <span className="text-gray-900 font-semibold">Strata Job Titles:</span>{" "}
                      {industries
                        .find((i) => i.industry_id === values.industry_id)
                        ?.strata_job_title_ids.map((id) => strataJobTitles.find((sjt) => sjt.strata_job_title_id === id)?.strata_job_title_name)
                        .join(", ")}
                    </div>
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label htmlFor="has_assessments" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Has Assessments
                    </label>
                    <p className="text-sm text-gray-500">If this account exists purely for org purposes to have all sub accounts map to it uncheck this</p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Toggle value={values.has_assessments} setValue={(value) => setFieldValue("has_assessments", value)} />
                    <ErrorMessage className="text-red-500 text-xs" name="has_assessments" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <div>
                    <label htmlFor="is_billing" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                      Is Billing
                    </label>
                    <p className="text-sm text-gray-500">Uncheck this if account is not paying for itself</p>
                  </div>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Toggle value={values.is_billing} setValue={(value) => setFieldValue("is_billing", value)} />
                    <ErrorMessage className="text-red-500 text-xs" name="is_billing" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_address_1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Street address line 1
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="company_address_1"
                      id="company_address_1"
                      autoComplete="street-address"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_address_1" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_address_2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    Street address line 2
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="company_address_2"
                      id="company_address_2"
                      autoComplete="street-address"
                      className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_address_2" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    City
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="company_city"
                      id="company_city"
                      autoComplete="address-level2"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_city" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_state" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    State / Province
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="company_state"
                      id="company_state"
                      autoComplete="address-level1"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_state" component="div" />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label htmlFor="company_zip" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                    ZIP / Postal code
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <Field
                      type="text"
                      name="company_zip"
                      id="company_zip"
                      autoComplete="postalCode-code"
                      className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    />
                    <ErrorMessage className="text-red-500 text-xs" name="company_zip" component="div" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="flex justify-start">
                <Button text="Cancel" variant="secondary" onClick={() => setEditable(false)} />
                <Button text="Edit" type="submit" className="ml-2" submitting={isSubmitting} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

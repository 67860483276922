import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useNavigate, useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { CompanyAdminUser, LeaderScore } from "../../types";
import useApi from "../../hooks/useApi";
import { ArrowLeftIcon, BuildingOfficeIcon, CheckIcon, EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { getInitialsFromName, getUserAttribute } from "../../libs/helpers";
import { useState } from "react";
import ConfirmModal from "../../components/ConfirmModal";

export default function LeaderDetails() {
  const { cognitoUserId } = useParams();
  const navigate = useNavigate();
  const { getApiData, postApiData } = useApi();
  const [scoreToSet, setScoreToSet] = useState<number>();

  const { data: companyAdminUsers } = useQuery<CompanyAdminUser[]>({
    queryKey: ["companyAdminUsers"],
    queryFn: () => getApiData(`/strata/admins`),
    initialData: [],
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies"],
    queryFn: () => getApiData(`/strata/companies`),
    initialData: [],
  });

  const leader = companyAdminUsers.find((cau) => cau.Username === cognitoUserId);

  if (!leader) {
    return <div>Leader not found</div>;
  }

  async function setScore() {
    const { response } = await postApiData(`/strata/admins/leader-score/${leader?.Username}`, { score: scoreToSet });
    if (response.ok) {
      alert("Leadership score set successfully");
      window.location.reload();
    } else {
      console.log(response);
      alert("There was an error setting leadership score");
    }
  }

  const scores = [
    { value: 1, name: "Level 1", description: "Skeptical", dateCompleted: leader.leaderScores.find((ls) => ls.leader_score === 1)?.created_at },
    { value: 2, name: "Level 2", description: "Cautiously Optimistic", dateCompleted: leader.leaderScores.find((ls) => ls.leader_score === 2)?.created_at },
    { value: 3, name: "Level 3", description: "Bought-in", dateCompleted: leader.leaderScores.find((ls) => ls.leader_score === 3)?.created_at },
  ];

  const maxLeaderScore = leader.leaderScores.length > 0 ? Math.max(...leader.leaderScores.map((ls) => ls.leader_score)) : null;

  return (
    <div>
      <ConfirmModal
        title="Set Score"
        text={`Do you want to update this leaders strata score to level ${scoreToSet}?`}
        open={!!scoreToSet}
        setOpen={(value) => (value ? setScoreToSet(scoreToSet) : setScoreToSet(undefined))}
        type="info"
        confirmText="Update Score"
        onConfirmAsync={setScore}
      />
      <div className="flex items-center cursor-pointer hover:underline text-gray-800" onClick={() => navigate(-1)}>
        <ArrowLeftIcon className="h-4 w-4" />
        <span className="ml-1">Back</span>
      </div>
      <div className="px-4 sm:px-0 mt-5">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Leader Info</h3>
      </div>
      <div className="border-b border-gray-200 bg-white py-5">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                {maxLeaderScore ? (
                  <div className="h-10 w-10 flex-none rounded-full bg-blue-700 text-white flex justify-center items-center">{maxLeaderScore}</div>
                ) : (
                  <div className="h-10 w-10 flex-none rounded-full bg-gray-400 text-white flex justify-center items-center">-</div>
                )}
              </div>
              <div className="ml-4">
                <h3 className="text-base font-semibold leading-6 text-gray-900">{`${getUserAttribute(leader, "given_name")} ${getUserAttribute(
                  leader,
                  "family_name"
                )}`}</h3>
                <div className="text-sm text-gray-700 mt-1 flex items-center gap-1">
                  <EnvelopeIcon className="size-4" />
                  <span className="truncate">{`${getUserAttribute(leader, "email")}`}</span>
                </div>

                <div className="text-sm text-gray-700 mt-1 flex items-center gap-1">
                  <PhoneIcon className="size-4" />
                  <span className="truncate">{`${getUserAttribute(leader, "phone_number") ?? "-"}`}</span>
                </div>

                <div className="text-sm text-gray-700 mt-1 flex items-center gap-1">
                  <BuildingOfficeIcon className="size-4" />
                  <span className="truncate">
                    {leader.companyIds.map((companyId) => companies.find((company) => company.company_id === companyId)?.company_name).join(", ")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-4 mt-4 flex flex-shrink-0">
            {!!getUserAttribute(leader, "phone_number") && (
              <a
                type="button"
                className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                href={`tel:${getUserAttribute(leader, "phone_number")}`}
              >
                <PhoneIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" />
                <span>Call</span>
              </a>
            )}
            <a
              type="button"
              className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              href={`mailto:${getUserAttribute(leader, "email")}`}
            >
              <EnvelopeIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" />
              <span>Email</span>
            </a>
          </div>
        </div>
      </div>
      <div>
        <div className="text-gray-700 font-semibold mt-5">Strata Score</div>
        <nav className="mt-2">
          <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
            {scores.map((step, stepIdx) => (
              <li
                key={step.name}
                className={`relative md:flex md:flex-1 ${!step.dateCompleted && "cursor-pointer"}`}
                onClick={() => !step.dateCompleted && setScoreToSet(step.value)}
              >
                <div className="group flex w-full items-center">
                  <span className="flex items-center px-6 py-4 text-sm font-medium">
                    {step.dateCompleted || (maxLeaderScore && stepIdx < maxLeaderScore) ? (
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-green-500">
                        <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
                      </span>
                    ) : (
                      <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500">{`0${step.value}`}</span>
                      </span>
                    )}
                    <div>
                      {step.dateCompleted && ((scores[stepIdx + 1] && !scores[stepIdx + 1].dateCompleted) || !scores[stepIdx + 1]) ? (
                        <div className="ml-4 text-blue-700 font-semibold">{step.name}</div>
                      ) : step.dateCompleted && scores[stepIdx + 1] && scores[stepIdx + 1].dateCompleted ? (
                        <div className="ml-4 text-sm font-medium text-gray-500">{step.name}</div>
                      ) : (
                        <div className="ml-4 text-sm font-medium text-gray-900">{step.name}</div>
                      )}
                      <div className="ml-4 text-xs text-gray-700">{step.description}</div>
                      {step.dateCompleted && <div className="ml-4 text-xs text-gray-500 italic">Reached on {new Date(step.dateCompleted).toLocaleDateString()}</div>}
                    </div>
                  </span>
                </div>
                {stepIdx !== scores.length - 1 ? (
                  <>
                    {/* Arrow separator for lg screens and up */}
                    <div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
                      <svg fill="none" viewBox="0 0 22 80" preserveAspectRatio="none" className="h-full w-full text-gray-300">
                        <path d="M0 -2L20 40L0 82" stroke="currentcolor" vectorEffect="non-scaling-stroke" strokeLinejoin="round" />
                      </svg>
                    </div>
                  </>
                ) : null}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  );
}

import { Link, useNavigate } from "react-router-dom";
import { CalendarDaysIcon, CalendarIcon, ChevronRightIcon, UsersIcon } from "@heroicons/react/24/outline";
import MonthSelect from "./MonthSelect";
import { useContext, useEffect, useState } from "react";
import YearSelect from "./YearSelect";
import CompanySelect from "./CompanySelect";
import Button from "./Button";
import { AuthContext } from "../context/authContext";
import { Role, Survey } from "../types";
import { PlusIcon } from "@heroicons/react/24/solid";
import StrataLogo from "../assets/black.png";
import { formatDistanceToNow } from "date-fns";
import { SI_VALUE_LOWER_CUTOFF, SI_VALUE_UPPER_CUTOFF } from "../constants";
import useApi from "../hooks/useApi";

export default function SurveyList(props: { surveys: Survey[]; hideCompanySelect?: boolean }) {
  let { surveys, hideCompanySelect } = props;

  const [surveyList, setSurveyList] = useState<Survey[]>([]);
  const [filteredMonth, setFilteredMonth] = useState<number | null>(null);
  const [filteredYear, setFilteredYear] = useState<number | null>(null);
  const [filteredCompany, setFilteredCompany] = useState<number | null>(null);
  const [filteredStatus, setFilteredStatus] = useState<"closed" | "current" | "future" | null>(null);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { postApiData } = useApi();
  const [updatingEvents, setUpdatingEvents] = useState(false);

  async function updateFutureEvents() {
    setUpdatingEvents(true);
    const { response } = await postApiData("/strata/surveys/events/update-future", {});
    setUpdatingEvents(false);
    if (!response.ok) {
      alert("An error occurred updating future ");
    } else {
      alert("Events added/updated successfully");
    }
  }

  useEffect(() => {
    let filteredSurveys = surveys;
    if (filteredMonth !== null) {
      filteredSurveys = filteredSurveys.filter((s) => new Date(s.end_date).getMonth() === filteredMonth);
    }
    if (filteredYear !== null) {
      filteredSurveys = filteredSurveys.filter((s) => new Date(s.end_date).getFullYear() === filteredYear);
    }
    if (filteredCompany !== null) {
      filteredSurveys = filteredSurveys.filter((s) => s.company_id === filteredCompany);
    }
    if (filteredStatus !== null) {
      filteredSurveys = filteredSurveys.filter((s) => {
        if (filteredStatus === "future") {
          return new Date() < new Date(s.start_date);
        }
        if (filteredStatus === "current") {
          return new Date() > new Date(s.start_date) && new Date(s.end_date) > new Date();
        }
        if (filteredStatus === "closed") {
          return new Date() > new Date(s.end_date);
        }
      });
    }
    setSurveyList(filteredSurveys);
  }, [surveys, filteredMonth, filteredYear, filteredCompany, filteredStatus]);

  surveys.sort((a, b) => new Date(b.end_date).getTime() - new Date(a.end_date).getTime());

  const DEMO_ASSESSMENT_IDENTIFIER = "[DEMO_ASSESSMENT]";

  return (
    <div className="flex flex-col">
      <div className="flex justify-between mb-4 overflow-x-scroll">
        <div className="flex gap-3 mb-1">
          <MonthSelect setMonth={setFilteredMonth} showAllOption={true} />
          <YearSelect
            setYear={setFilteredYear}
            fromYear={Math.min(...surveys.map((s) => new Date(s.end_date).getFullYear()))}
            toYear={new Date().getFullYear()}
            showAllOption={true}
          />
          <div>
            <label htmlFor="status" className="block text-sm font-light leading-6 text-gray-900">
              Status
            </label>
            <select
              id="status"
              name="status"
              className="block w-26 rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
              onChange={(e) => (e.target.value ? setFilteredStatus(e.target.value as "closed" | "current" | "future") : setFilteredStatus(null))}
            >
              <option value="">All</option>
              <option value="future">Upcoming</option>
              <option value="current">In Progress</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          {!hideCompanySelect && <CompanySelect setCompanyId={setFilteredCompany} showAllOption={true} />}
        </div>
        <div className="flex gap-2 m-1">
          {auth.role === Role.Manager && <Button text="Create Assessment" className="mt-5" onClick={() => navigate("/assessments/create")} icon={PlusIcon} />}
          <Button
            className="mt-5"
            text="Update Events"
            icon={CalendarDaysIcon}
            variant="secondary"
            onClick={() => {
              if (window.confirm("This will create or update all events for all current and future assessments")) {
                updateFutureEvents();
              }
            }}
            submitting={updatingEvents}
          />
        </div>
      </div>
      {surveyList.length === 0 ? (
        <div className="text-center mt-4">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No Assessments</h3>
          <p className="mt-1 text-sm text-gray-500">Try changing your filter selections</p>
        </div>
      ) : (
        <ul className="">
          {surveyList
            .filter((s) => hideCompanySelect || !s.survey_name.includes(DEMO_ASSESSMENT_IDENTIFIER))
            .map((survey, index: number) => (
              <Link key={index} to={`/assessment/${survey.survey_id}`}>
                <li className="flex items-center justify-between border rounded shadow mb-3 px-4 py-3">
                  <div className="min-w-0 w-2/3">
                    <div className="flex items-start gap-x-1">
                      <p className="text-sm leading-6 text-gray-900">{survey.survey_name}</p>
                      <p className="text-sm leading-6 text-gray-500">
                        (#{surveys.filter((s) => s.company_id === survey.company_id && new Date(s.start_date) <= new Date(survey.start_date)).length})
                      </p>
                    </div>
                    <div className="flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                      <div className="whitespace-nowrap">
                        {new Date() < new Date(survey.start_date) ? (
                          <p className="text-gray-500">
                            Starting on <time dateTime={survey.start_date}>{new Date(survey.start_date).toLocaleString("en-US")}</time>
                          </p>
                        ) : new Date() > new Date(survey.start_date) && new Date(survey.end_date) > new Date() ? (
                          <p className="text-green-500">
                            In Progress - Closing on <time dateTime={survey.end_date}>{new Date(survey.end_date).toLocaleString("en-US")}</time>
                          </p>
                        ) : (
                          <p className="text-red-500">
                            Closed on <time dateTime={survey.end_date}>{new Date(survey.end_date).toLocaleString("en-US")}</time>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  {new Date() < new Date(survey.start_date) ? (
                    <div className="text-gray-500 text-sm">Starting in {formatDistanceToNow(new Date(survey.start_date))}</div>
                  ) : new Date() > new Date(survey.start_date) && new Date(survey.end_date) > new Date() ? (
                    <div className="text-gray-500 text-sm">Closing in {formatDistanceToNow(new Date(survey.end_date))}</div>
                  ) : (
                    <div className="">
                      {survey.si_value && survey.response_rate ? (
                        <div>
                          <div className="flex items-center justify-between">
                            <div
                              className={`${
                                parseFloat(survey.si_value) > SI_VALUE_UPPER_CUTOFF
                                  ? "text-green-500"
                                  : parseFloat(survey.si_value) > SI_VALUE_LOWER_CUTOFF
                                  ? "text-yellow-500"
                                  : "text-red-500"
                              } text-right text-sm font-bold flex items-center`}
                            >
                              <img src={StrataLogo} className="h-5 mr-1" /> {parseFloat(survey.si_value).toFixed(1)}
                            </div>
                            <div className="text-right text-gray-500 text-sm font-bold flex items-center">
                              <UsersIcon className="h-5 mr-1" /> {parseFloat(survey.response_rate).toFixed(1)}%
                            </div>
                          </div>
                          <div className="flex justify-between gap-x-4 mt-1">
                            <dd className="flex items-start gap-x-1">
                              <div className="rounded-md text-xs font-medium ring-1 ring-red-600 ring-inset bg-red-500 text-white w-8 h-6 flex items-center justify-center">
                                {survey.red_count}
                              </div>
                              <div className="rounded-md text-xs font-medium ring-1 ring-yellow-600 ring-inset bg-yellow-500 text-white w-8 h-6 flex items-center justify-center">
                                {survey.yellow_count}
                              </div>
                              <div className="rounded-md text-xs font-medium ring-1 ring-green-600 ring-inset bg-green-500 text-white w-8 h-6 flex items-center justify-center">
                                {survey.green_count}
                              </div>
                              <div className="rounded-md text-xs font-medium ring-1 ring-gray-500 ring-inset bg-gray-400 text-white w-8 h-6 flex items-center justify-center">
                                {survey.disengaged_count}
                              </div>
                            </dd>
                          </div>
                        </div>
                      ) : (
                        <div className="text-gray-400 text-sm">No Results</div>
                      )}
                    </div>
                  )}
                </li>
              </Link>
            ))}
        </ul>
      )}
    </div>
  );
}

import { Bar } from "react-chartjs-2";
import colors from "tailwindcss/colors";
import { Respondent } from "../../types";
import Tooltip from "../../components/Tooltip";
import { BarElement, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js";
import { useEffect, useState } from "react";
import { PercentBadgeIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Toggle from "../../components/Toggle";
import customIcons from "../../assets/customIcons";
import PercentSignIcon from "../../assets/customIcons/percent-svgrepo-com.svg";
import { DISENGAGED_UPPER_CUTOFF, HIGH_GREEN_UPPER_CUTOFF, HIGH_YELLOW_UPPER_CUTOFF, LOW_RED_UPPER_CUTOFF, LOW_YELLOW_UPPER_CUTOFF, MID_GREEN_UPPER_CUTOFF, MID_RED_UPPER_CUTOFF, MID_YELLOW_UPPER_CUTOFF } from "../../constants";

ChartJS.register(BarElement);

export default function RespondentDistribution(props: { respondents: Array<Respondent>; printable?: boolean }) {
  const { respondents, printable } = props;

  const [categoryDescription, setCategoryDescription] = useState("Hover over each bar to see the number of individuals and what they are experiencing");
  const [color, setColor] = useState("");
  const [barCount, setBarCount] = useState(0);
  const [calcType, setCalcType] = useState<"Count" | "Percentage">("Count");

  const labels: Array<string> = ["High Red", "Mid Red", "Low Red", "High Yellow", "Mid Yellow", "Low Yellow", "High Green", "Mid Green", "Disengaged"];
  let chartData: Array<number> = [0, 0, 0, 0, 0, 0, 0, 0, 0];

  for (let i = 0; i < respondents.length; i++) {
    const siValue = parseFloat(respondents[i].mental_health_value);

    if (siValue > MID_RED_UPPER_CUTOFF) {
      chartData[0]++;
    }
    if (siValue > LOW_RED_UPPER_CUTOFF && siValue <= MID_RED_UPPER_CUTOFF) {
      chartData[1]++;
    }
    if (siValue > HIGH_YELLOW_UPPER_CUTOFF && siValue <= LOW_RED_UPPER_CUTOFF) {
      chartData[2]++;
    }
    if (siValue > MID_YELLOW_UPPER_CUTOFF && siValue <= HIGH_YELLOW_UPPER_CUTOFF) {
      chartData[3]++;
    }
    if (siValue > LOW_YELLOW_UPPER_CUTOFF && siValue <= MID_YELLOW_UPPER_CUTOFF) {
      chartData[4]++;
    }
    if (siValue > HIGH_GREEN_UPPER_CUTOFF && siValue <= LOW_YELLOW_UPPER_CUTOFF) {
      chartData[5]++;
    }
    if (siValue > MID_GREEN_UPPER_CUTOFF && siValue <= HIGH_GREEN_UPPER_CUTOFF) {
      chartData[6]++;
    }
    if (siValue > DISENGAGED_UPPER_CUTOFF && siValue <= MID_GREEN_UPPER_CUTOFF) {
      chartData[7]++;
    }
    if (siValue <= DISENGAGED_UPPER_CUTOFF) {
      chartData[8]++;
    }
  }

  if (calcType === "Percentage") {
    const totalRespondents = chartData.reduce((a, b) => a + b, 0);
    for (let i = 0; i < chartData.length; i++) {
      chartData[i] = chartData[i] / totalRespondents;
    }
  }

  const colorRed = colors.red[300];
  const colorYellow = colors.yellow[300];
  const colorGreen = colors.green[400];
  const colorGray = colors.gray[300];

  const data: ChartData<"bar", number[], string> = {
    labels,
    datasets: [
      {
        label: "",
        data: chartData,
        backgroundColor: [colorRed, colorRed, colorRed, colorYellow, colorYellow, colorYellow, colorGreen, colorGreen, colorGray],
      },
    ],
  };

  function generateTooltip(tooltipItems: any): string {
    const label = tooltipItems[0].label;
    let color = "";
    let text = "";
    switch (label) {
      case labels[8]: {
        text = `Disconnection and Underperformance
- Individuals exhibiting a disconnection from their job or workplace`;
        color = colors.gray[400];
        break;
      }
      case labels[7]:
        text = `Positive Engagement and Potential for Growth
- Individuals engaged and actively seek ways to contribute to their own success and that of the organization`;
        color = colorGreen;
        break;
      case labels[6]:
        text = `Optimal Performance and Healthy Stress Levels
- Individuals consistently putting forth effort and experiencing appropriate levels of stress`;
        color = colorGreen;
        break;
      case labels[5]:
        text = `Occasional Stress Impacting Performance
- Individuals occasionally experiencing stress that interferes with decision-making`;
        color = colorYellow;
        break;
      case labels[4]:
        text = `Routine Stress Affecting Performance
- Individuals routinely experiencing stress that impacts performance, concentration, focus, and decision-making`;
        color = colorYellow;
        break;
      case labels[3]:
        text = `Distressing Levels of Stress
- Individuals experiencing distressing levels of stress that significantly impact overall functioning`;
        color = colorYellow;
        break;
      default:
        text = `Stress and Dysfunction
- Individuals experiencing extreme levels of stress and dysfunction (requires immediate attention)`;
        color = colorRed;
        break;
    }

    setBarCount(tooltipItems[0].raw);
    setColor(color);
    setCategoryDescription(text);
    return "";
  }

  useEffect(() => {
    setBarCount(0);
    setColor("");
    setCategoryDescription("Hover over each bar to see the number of individuals and what they are experiencing");
  }, [calcType]);

  return (
    <div className={`${printable ? "h-full" : "h-[80%]"}`}>
      {!printable && (
        <div className="flex items-center justify-between mb-3">
          <div className={`w-full sm:w-11/12 border-2 flex content-center items-center px-6 py-2`}>
            <div className="h-10 w-10 border mr-4 flex text-center items-center content-center justify-center" style={{ borderColor: color }}>
              <p>{calcType === "Count" ? barCount : `${(barCount * 100).toFixed(0)}%`}</p>
            </div>
            <p className="text-sm text-gray-800">{categoryDescription}</p>
          </div>
          <div className="hidden sm:flex sm:w-1/12 justify-center items-center text-gray-500 hover:text-gray-700 cursor-pointer">
            <Toggle
              value={calcType === "Percentage"}
              setValue={(value) => (value ? setCalcType("Percentage") : setCalcType("Count"))}
              offIcon={customIcons.percentSign}
              onIcon={customIcons.user}
            />
          </div>
        </div>
      )}
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: "white",
          // aspectRatio: 4,
          plugins: {
            legend: {
              position: "top" as const,
              display: false,
            },
            datalabels: {
              display: false, //printable ? true : false,
              align: "top",
              color: colors.gray[500],
              font: {
                size: 14,
                weight: "bold",
              },
            },
            title: {
              display: false,
              text: "Bar Chart",
            },
            tooltip: {
              callbacks: {
                //afterBody: generateTooltip,
                footer: generateTooltip,
              },
            },
          },
          scales: {
            y: {
              title: {
                text: calcType === "Percentage" ? "Percent of Respondents" : "Number of Respondents",
                display: true,
              },
              ticks: {
                format: {
                  style: calcType === "Percentage" ? "percent" : undefined,
                },
                stepSize: calcType === "Percentage" ? 0.1 : 5,
                callback: function (value) {
                  if (calcType === "Percentage") {
                    //@ts-ignore
                    return value * 100 + "%"; // Round to integer and add the percentage symbol
                  } else {
                    return value;
                  }
                },
              },
            },
            x: {
              grid: {
                display: false,
              },
            },
          },
        }}
        data={data}
      />
    </div>
  );
}

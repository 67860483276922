import { useContext } from "react";
import { useNavigate } from "react-router";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/authContext";
import { useQuery } from "@tanstack/react-query";
import apiGet from "../../libs/helpers";
import Toggle from "../../components/Toggle";

export default function CreateCompany() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  const { data: companyTypes } = useQuery<any[]>({
    queryKey: ["companyTypes", auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/companies/get/types`, auth.sessionInfo?.idToken),
    initialData: [],
  });

  const { data: companies } = useQuery<Company[]>({
    queryKey: ["companies", auth.sessionInfo?.idToken],
    queryFn: () => apiGet(`/strata/companies`, auth.sessionInfo?.idToken),
    initialData: [],
  });

  return (
    <main className="flex-1">
      <div className="py-6">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-semibold text-zinc-900">Create a New Company</h1>
        </div>
        <div className="max-w-2xl mx-9 my-7">
          <Formik
            initialValues={{
              name: "",
              typeId: 1,
              parentId: null,
              hasAssessments: true,
              isBilling: true,
              address1: "",
              address2: "",
              city: "",
              region: "",
              postalCode: "",
              notes: "",
              all: "",
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.name) {
                errors.name = "Company name is required";
              } else if (values.name.length > 255) {
                errors.name = "Company name must be 255 characters or less";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, setErrors }) => {
              setSubmitting(true);
              try {
                fetch(`${process.env.REACT_APP_API_URL}/strata/companies`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: auth.sessionInfo?.idToken ? auth.sessionInfo.idToken : "",
                  },
                  body: JSON.stringify(values),
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((body) => {
                    setSubmitting(false);
                    navigate("/companies");
                  });
              } catch (err: any) {
                setErrors({ all: err.message });
              }
            }}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form>
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                    <div>
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Company Information</h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">This is the information needed in order to create an company.</p>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Company name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="name" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Company type
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            as="select"
                            name="type"
                            id="type"
                            autoComplete="type"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          >
                            {companyTypes.map((type: any, index: number) => (
                              <option key={index} value={type.company_type_id}>
                                {type.company_type_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage className="text-red-500 text-xs" name="type" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Parent Company
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            as="select"
                            name="parentId"
                            id="parentId"
                            onChange={(e: any) => setFieldValue("parentId", parseInt(e.target.value))}
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          >
                            <option value={""}>None</option>
                            {companies.map((company, index) => (
                              <option key={index} value={company.company_id}>
                                {company.company_name}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage className="text-red-500 text-xs" name="parentId" component="div" />
                        </div>
                        <div className="col-span-3">
                          <p className="text-sm text-gray-500">NOTE: Create the parent first and then select the parent company when creating its children</p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <div>
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Has Assessments
                        </label>
                        </div>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Toggle value={values.hasAssessments} setValue={value => setFieldValue("hasAssessments", value)} />
                          <ErrorMessage className="text-red-500 text-xs" name="hasAssessments" component="div" />
                        </div>
                        <div className="col-span-3">
                          <p className="text-sm text-gray-500">If this account exists purely for org purposes to have all sub accounts map to it uncheck this</p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Is Billing
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Toggle value={values.isBilling} setValue={value => setFieldValue("isBilling", value)} />
                          <ErrorMessage className="text-red-500 text-xs" name="hasAssessments" component="div" />
                        </div>
                        <div className="col-span-3">
                          <p className="text-sm text-gray-500">Uncheck this if account is not paying for itself</p>
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="address1" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Street address line 1
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="address1"
                            id="address1"
                            autoComplete="street-address"
                            className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="address1" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="address2" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Street address line 2
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="address2"
                            id="address2"
                            autoComplete="street-address"
                            className="block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="address2" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="city" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          City
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="city"
                            id="city"
                            autoComplete="address-level2"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="city" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="region" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          State / Province
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="region"
                            id="region"
                            autoComplete="address-level1"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="region" component="div" />
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label htmlFor="postalCode-code" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          ZIP / Postal code
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <Field
                            type="text"
                            name="postalCode"
                            id="postalCode"
                            autoComplete="postalCode-code"
                            className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                          />
                          <ErrorMessage className="text-red-500 text-xs" name="postalCode" component="div" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-5">
                  <div className="flex justify-end">
                    <button
                      type="button"
                      className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => navigate('/companies')}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
}

const percentSign: any = (props: any) => (
    <svg
      viewBox="0 0 250 250" // viewbox can be used to make icon same size as hero icons
      className={`${props.className} scale-75`}
      fill="currentColor" // use currentColor to allow text-* Tailwind classes to apply
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <g>
          <path d="M193.882,8.561c-7.383-3.756-16.414-0.813-20.169,6.573L62.153,234.556c-3.755,7.385-0.812,16.414,6.573,20.169 c2.178,1.107,4.499,1.632,6.786,1.632c5.466,0,10.735-2.998,13.383-8.205L200.455,28.73 C204.21,21.345,201.267,12.316,193.882,8.561z" />
          <path d="M113.778,80.818c0-31.369-25.521-56.89-56.89-56.89C25.521,23.928,0,49.449,0,80.818c0,31.368,25.521,56.889,56.889,56.889 C88.258,137.707,113.778,112.186,113.778,80.818z M56.889,107.707C42.063,107.707,30,95.644,30,80.818 c0-14.827,12.063-26.89,26.889-26.89c14.827,0,26.89,12.062,26.89,26.89C83.778,95.644,71.716,107.707,56.889,107.707z" />
          <path d="M206.396,125.58c-31.369,0-56.89,25.521-56.89,56.889c0,31.368,25.52,56.889,56.89,56.889 c31.368,0,56.889-25.52,56.889-56.889C263.285,151.1,237.765,125.58,206.396,125.58z M206.396,209.357 c-14.827,0-26.89-12.063-26.89-26.889c0-14.826,12.063-26.889,26.89-26.889c14.826,0,26.889,12.063,26.889,26.889 C233.285,197.294,221.223,209.357,206.396,209.357z" />
        </g>
      </g>
    </svg>
  );
  
  const user: any = (props: any) => (
    <svg viewBox="0 0 24 24" className={`${props.className}`} stroke="currentColor" fill="none">
      <path
        d="M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 14C8.13401 14 5 17.134 5 21H19C19 17.134 15.866 14 12 14Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
  
  const calendarEvent: any = (props: any) => (
    <svg viewBox="0 0 122.87 122.88" className={`${props.className}`} stroke="currentColor" fill="none">
      <path d="M81.6,4.73C81.6,2.12,84.18,0,87.37,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73Zm-19,56.57,6,13.91,15.07,1.35a1.2,1.2,0,0,1,1.1,1.31,1.18,1.18,0,0,1-.41.81h0l-11.41,10,3.37,14.75a1.2,1.2,0,0,1-.91,1.45,1.27,1.27,0,0,1-.94-.17l-13-7.74-13,7.78a1.22,1.22,0,0,1-1.66-.42,1.2,1.2,0,0,1-.14-.9h0L50,88.64l-11.4-10A1.22,1.22,0,0,1,38.48,77a1.26,1.26,0,0,1,.86-.4l15-1.34,6-13.93a1.21,1.21,0,0,1,1.59-.64,1.17,1.17,0,0,1,.65.64ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,45.32H116.47V21.47a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,1,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.08a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.54V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07V45.32Zm110.07,6.41H6.4v61.81a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.54a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V51.73Zm-66-33.19a3.2,3.2,0,0,1,0-6.4H71.91a3.2,3.2,0,1,1,0,6.4Z" />
    </svg>
  );
  
  export default {
    percentSign,
    user,
  };
  
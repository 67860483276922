import { useState, useContext, useEffect } from "react";
import Button from "../../components/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { AuthContext } from "../../context/authContext";

export default function PointOfContactEditable(props: {
  pointOfContact: any;
  isUpdate: boolean;
  companyId: number;
}) {
  const { pointOfContact, isUpdate, companyId } = props;
  const auth = useContext(AuthContext);
  const [pocTypes, setPocTypes] = useState<Array<any>>([]);

  useEffect(() => {
    async function getPointOfContactTypes() {
      try {
        if (auth.sessionInfo?.idToken) {
          await fetch(
            `${process.env.REACT_APP_API_URL}/strata/point-of-contacts/get/types`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: auth.sessionInfo.idToken,
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.err) {
                console.error(data);
              } else {
                setPocTypes(data);
              }
            });
        } else {
          window.location.reload();
        }
      } catch (err) {
        console.error(err);
      }
    }
    getPointOfContactTypes();
  }, []);

  async function deletePointOfContact(pocId: number) {
    try {
      if (auth.sessionInfo?.idToken) {
        await fetch(
          `${process.env.REACT_APP_API_URL}/strata/point-of-contacts/${pocId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.sessionInfo.idToken,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data.err) {
              console.error(data);
            } else {
              window.location.reload();
            }
          });
      } else {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <div className="mb-5">
      <h5 className="text-lg font-bold text-gray-700 mb-3">
        {isUpdate ? `Edit ${pointOfContact?.poc_type_name}` : "Add New"} Contact
      </h5>
      <Formik
        initialValues={{
          name: pointOfContact?.poc_name,
          email: pointOfContact?.poc_email,
          phone: pointOfContact?.poc_phone_number,
          address1: pointOfContact?.poc_address_1,
          address2: pointOfContact?.poc_address_2,
          notes: pointOfContact?.notes,
          type: pointOfContact?.poc_type_id ?? 1,
          all: "",
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.name) {
            errors.name = "Name is required";
          }
          if (!values.email) {
            errors.email = "Email is required";
          }
          if (!values.type) {
            errors.type = "Type is required";
          }
          return errors;
        }}
        onSubmit={(values, { setErrors }) => {
          let url = "";
          let method = "";

          if (isUpdate) {
            url = `${process.env.REACT_APP_API_URL}/strata/point-of-contacts/${pointOfContact.poc_id}`;
            method = "PUT";
          } else {
            url = `${process.env.REACT_APP_API_URL}/strata/point-of-contacts`;
            method = "POST";
          }
          try {
            fetch(url, {
              method: method,
              headers: {
                "Content-Type": "application/json",
                Authorization: auth.sessionInfo?.idToken
                  ? auth.sessionInfo.idToken
                  : "",
              },
              body: JSON.stringify({
                pocId: pointOfContact?.poc_id,
                companyId: pointOfContact?.company_id || companyId,
                name: values.name,
                email: values.email,
                phone: values.phone,
                address1: values.address1,
                address2: values.address2,
                notes: values.notes,
                type: values.type
              }),
            })
              .then((res) => {
                if(res.status === 200) {
                  window.location.reload();
                } else {
                  console.error(res);
                  alert("An error occurred creating point of contact")
                }
              });
          } catch (err: any) {
            setErrors({ all: err.message });
          }
        }}
      >
        {({ isSubmitting, setFieldValue }) => (
          <Form>
            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Type*
              </label>
              <Field
                as="select"
                name="type"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              >
                {pocTypes.map((type: any, index: number) => (
                  <option key={index} value={type.poc_type_id}>
                    {type.poc_type_name}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                className="text-red-500 text-xs"
                name="type"
                component="div"
              />
            </div>
            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name*
              </label>
              <Field
                type="text"
                name="name"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="name"
                component="div"
              />
            </div>
            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email*
              </label>
              <Field
                type="email"
                name="email"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="email"
                component="div"
              />
            </div>

            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <Field
                type="tel"
                name="phone"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="phone"
                component="div"
              />
            </div>

            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="address1"
                className="block text-sm font-medium text-gray-700"
              >
                Address line 1
              </label>
              <Field
                type="text"
                name="address1"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="address1"
                component="div"
              />
            </div>

            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="address2"
                className="block text-sm font-medium text-gray-700"
              >
                Address line 2
              </label>
              <Field
                type="text"
                name="address2"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="address2"
                component="div"
              />
            </div>

            <div className="relative z-0 mb-6 w-1/2 group">
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700"
              >
                Notes
              </label>
              <Field
                as="textarea"
                rows={4}
                name="notes"
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder=" "
              />
              <ErrorMessage
                className="text-red-500 text-xs"
                name="notes"
                component="div"
              />
            </div>

            <div className="flex">
              <Button
                text={`${isUpdate ? "Edit" : "Add"} Point of Contact`}
                submitting={isSubmitting}
                type="submit"
              />
              {isUpdate && (
                <Button
                  text={`Delete`}
                  submitting={isSubmitting}
                  type="button"
                  variant="danger"
                  onClick={() => deletePointOfContact(pointOfContact.poc_id)}
                  className="ml-5"
                />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

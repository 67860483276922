import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";
import Spinner from "../../components/Spinner";
import Button from "../../components/Button";
import AssesmentOverview from "../../components/AssesmentOverview";
import UsersTableWithPagination from "../../components/assessments/UsersTableWithPagination";
import EmailSentAlert from "../../components/EmailSentAlert";
import SurveyKeysTableWithPagination from "../../components/assessments/SurveyKeysTableWithPagination";
import WarningAlert from "../../components/WarningAlert";
import Report from "../../components/Report/Report";
import { CompanyAdminUser, Respondent, Response, Role, Survey, SurveyEvent, SurveyKey, User } from "../../types";
import CloseAssessmentModal from "../../components/assessments/CloseAssessmentModal";
import { useQuery } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { ArrowLeftIcon, EllipsisVerticalIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Menu } from "@headlessui/react";
import ConfirmModal from "../../components/ConfirmModal";
import SurveyEvents from "./SurveyEvents";
import CollapsableContainer from "../../components/CollapsableContainer";
import { ArrowDownOnSquareIcon, CloudArrowDownIcon, EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function AssessmentDetails() {
  const [showEmailAlert, setShowEmailAlert] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    title: string;
    body: string;
  }>();
  const [sentTo, setSentTo] = useState<Array<any>>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { getApiData, postApiData, deleteApiData } = useApi();

  const auth = useContext(AuthContext);

  const assessmentId = location.pathname.replace("/assessment/", "");

  const { data: survey } = useQuery<Survey>({
    queryKey: ["survey", assessmentId],
    queryFn: async () => getApiData(`/strata/surveys/${assessmentId}`),
  });

  const { data: users } = useQuery<User[]>({
    queryKey: ["users", survey?.company_id],
    queryFn: async () => getApiData(`/strata/companies/users/${survey?.company_id}`),
    initialData: [],
    enabled: !!survey,
  });

  const { data: respondents } = useQuery<Respondent[]>({
    queryKey: ["responses", assessmentId],
    queryFn: async () => getApiData(`/strata/surveys/respondents/${assessmentId}`),
    initialData: [],
    enabled: !!survey,
  });

  const { data: surveyKeys } = useQuery<SurveyKey[]>({
    queryKey: ["surveyKeys", assessmentId],
    queryFn: async () => getApiData(`/strata/surveys/surveyKeys/${assessmentId}`),
    initialData: [],
    enabled: !!survey,
  });

  const { data: surveyEvents } = useQuery<SurveyEvent[]>({
    queryKey: ["surveyEvents", assessmentId],
    queryFn: async () => getApiData(`/strata/surveys/events/${assessmentId}`),
    initialData: [],
    enabled: !!assessmentId,
  });

  let latestExpirationDate = null as null | string;
  if (surveyKeys.length > 0 && surveyKeys.filter((key) => !key.is_taken).length > 0) {
    latestExpirationDate = surveyKeys.filter((key) => !key.is_taken).reduce((a, b) => (a.expiration_date > b.expiration_date ? a : b))?.expiration_date ?? null;
  }

  async function closeAssessment() {
    setSubmitting(true);
    try {
      if (auth.sessionInfo?.idToken) {
        await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/close/${assessmentId}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.sessionInfo.idToken,
          },
          body: JSON.stringify({}),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.err) {
              console.error(data);
            } else {
              window.location.reload();
            }
          });
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }

    setOpen(false);
    setSubmitting(false);
  }

  async function deleteSurvey() {
    try {
      const { response } = await deleteApiData(`/strata/surveys/${assessmentId}`);
      if (response.ok) {
        alert("Deleted Successfully");
        navigate(-1);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function startSurvey() {
    setSubmitting(true);
    if (survey && new Date() < new Date(survey.start_date)) {
      setErrorMessage({
        title: "Rookie Mistake",
        body: "Come on rookie, you can't send keys before the survey start date!",
      });
    } else if (latestExpirationDate && new Date() < new Date(latestExpirationDate)) {
      setErrorMessage({
        title: "Active Surveys in Progress",
        body: `There are still active surveys in progress. Please wait until ${new Date(latestExpirationDate).toLocaleDateString()} ${new Date(
          latestExpirationDate
        ).toLocaleTimeString()} before sending new assessment keys`,
      });
    } else {
      try {
        if (auth.sessionInfo?.idToken && survey) {
          await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/start-group`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: auth.sessionInfo.idToken,
            },
            body: JSON.stringify({
              surveyId: survey.survey_id,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.err) {
                console.error(data);
              } else {
                if (data.noUsersMessage) {
                  alert(data.noUsersMessage);
                  return;
                }
                setSentTo(data.sentTo);
                setShowEmailAlert(true);
              }
            });
        } else {
          navigate("/login");
        }
      } catch (err) {
        console.error(err);
      }
    }
    setSubmitting(false);
  }

  async function sendReminderEmails(isFinal: boolean) {
    setSubmitting(true);
    try {
      if (auth.sessionInfo?.idToken && survey) {
        await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/${isFinal ? "final-reminder" : "reminder"}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: auth.sessionInfo.idToken,
          },
          body: JSON.stringify({
            surveyId: survey.survey_id,
            companyName: survey.company_name,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.err) {
              console.error(data);
            } else {
              if (data.noUsersMessage) {
                alert(data.noUsersMessage);
                return;
              }
              setSentTo(data.sentTo);
              setShowEmailAlert(true);
            }
          });
      } else {
        navigate("/login");
      }
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
  }

  async function getPDFResults() {
    setLoadingPDF(true);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/strata/surveys/pdf/${assessmentId}`, {
      method: "GET",
      headers: {
        Authorization: auth?.sessionInfo?.idToken ?? "",
      },
    });

    const arrayBuffer = await response.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const blob = new Blob([uint8Array], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    // open the PDF in a new tab
    window.open(blobUrl, "_blank");
    setLoadingPDF(false);
  }

  const stats = [
    { name: "Assessments Sent", value: surveyKeys.length },
    { name: "Responses", value: respondents.length },
    {
      name: "Response Rate",
      value: `${((respondents.length / surveyKeys.length) * 100).toFixed(2)}%`,
    },
  ];

  if (survey) {
    return (
      <div>
        <div className="flex items-center cursor-pointer hover:underline text-gray-800" onClick={() => navigate(-1)}>
          <ArrowLeftIcon className="h-4 w-4" />
          <span className="ml-1">Back</span>
        </div>

        <div className="mt-3">
          <AssesmentOverview survey={survey} deleteSurvey={deleteSurvey} />

          <CollapsableContainer title={`Events (${surveyEvents.length})`} className="mt-6">
            <SurveyEvents surveyEvents={surveyEvents} surveyId={survey.survey_id} />
          </CollapsableContainer>

          <CollapsableContainer title={`Assessment Keys (${surveyKeys.length})`} className="mt-6">
            <SurveyKeysTableWithPagination surveyKeys={surveyKeys} limit={10} />
            {auth.role === Role.Manager && (
              <div className="mb-5">
                <div className="justify-between items-center mb-3 mt-10">
                  <h3 className="text-xl font-bold leading-none text-zinc-900">Assign Keys and Send Emails/Texts</h3>
                </div>

                {showEmailAlert && <EmailSentAlert setShowAlert={(show: boolean) => setShowEmailAlert(show)} sentTo={sentTo} />}

                {errorMessage && <WarningAlert title={errorMessage.title} body={errorMessage.body} dismiss={() => setErrorMessage(undefined)} />}

                <div className="flex gap-3">
                  <Button text="Assign and Send" onClick={() => startSurvey()} submitting={submitting} />

                  <Button text="Send Reminders" variant={"secondary"} onClick={() => sendReminderEmails(false)} submitting={submitting} />
                </div>
              </div>
            )}
          </CollapsableContainer>

          <CollapsableContainer title={`Current Participants (${users.length})`} className="mt-6">
            <UsersTableWithPagination users={users} surveyKeys={surveyKeys} limit={10} />
          </CollapsableContainer>

          {/* Active Results Counter */}
          {new Date(survey.start_date) < new Date() && (
            <div className="bg-white rounded-lg border shadow-md px-4 py-4 sm:p-8 mt-6">
              <h3 className="text-xl font-bold leading-none text-zinc-900">Active Results</h3>

              <div className="mx-auto">
                <div className="grid grid-cols-1 gap-px sm:grid-cols-2 lg:grid-cols-4 mt-1 sm:mt-0">
                  {stats.map((stat) => (
                    <div key={stat.name} className="bg-white py-2 sm:py-6">
                      <p className="text-sm font-medium leading-6 text-black-400">{stat.name}</p>
                      <p className="mt-0 sm:mt-2 flex items-baseline gap-x-2">
                        <span className="text-4xl font-semibold tracking-tight text-black">{stat.value}</span>
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex gap-4">
                  <Button
                    text={showResults ? "Hide Preview" : "Preview Results"}
                    onClick={() => setShowResults(!showResults)}
                    submitting={submitting}
                    icon={showResults ? EyeSlashIcon : EyeIcon}
                  />
                  {new Date(survey.end_date) < new Date() && (
                    <Button text="Download PDF Results" onClick={() => getPDFResults()} variant="secondary" submitting={loadingPDF} icon={CloudArrowDownIcon} />
                  )}
                </div>
              </div>
            </div>
          )}

          <CloseAssessmentModal open={open} setOpen={setOpen} closeAssessment={closeAssessment} />

          {showResults && (
            <div className="bg-gray-50 rounded-lg border shadow-md sm:p-8 mt-6">
              <Report survey={survey} />
            </div>
          )}

          <div className="mt-8 flex">
            <div className="mr-2">
              <Button text="Back" onClick={() => navigate(-1)} variant="secondary" />
            </div>

            {auth.role === Role.Manager && new Date(survey.start_date) < new Date() && (
              <Button text="Calculate Assessment Results" onClick={() => setOpen(true)} variant="danger" submitting={submitting} />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return <Spinner />;
  }
}
